/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../../components/seo"

import Page from "../../layouts/page"

import { Link } from "gatsby"

const LegalPage = () => {
  return <Page>
    <div>
      <div className="contain page-body">
        <ol class="uk-breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li><span>Network</span></li>
        </ol>

        <h1>Network</h1>

        <ul>
          <li><Link to="/network/peering">Peering Policy</Link></li>
        </ul>
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Network"
    path="/network"
  />
)

export default LegalPage
